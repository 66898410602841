import { motion } from "framer-motion";
import { variantPages } from "../animations/pageTransition";
import React from "react";
import MapRoute from "../components/mapRoute";
import Feedback from "../components/service/feedback";
import MetaTag from "../components/metaTag";
import Footer from "../components/footer";
import { directionsSEO } from "../data/seo";
import links from "../data/directions.json";
import * as styles from "../styles/pages/services.module.scss";
import { Link } from "gatsby";

function Directions() {
  return (
    <motion.main
      variants={variantPages}
      initial="initial"
      exit="exit"
      animate="animate"
    >
      <MetaTag data={directionsSEO} />
      <section className={styles.container}>
        <div className={styles.title}>
          <h1>Актуальные направления перевозки автомобиля</h1>
        </div>

        <div className={styles.directionsinfo}>
          <p className={styles.text}>
            Заказывайте перевозку автомобилей по России и СНГ от компании №1.
            Единственное, что вы должны знать – мы можем решить любую задачу!
            Каждую минуту в нашу диспетчерскую поступает новый заказ. Компания
            «Автовоз Логистик» легко доставит автомобиль до нужной точки на
            карте.
            <br />
            <br />
            В зависимости от габаритов автомобиля, состояния, стоимости,
            направления и расстояния перевозки мы подберём для вас выгодный
            тариф. Уточнить цену можно у менеджеров, который на связи 24/7 по
            телефону и электронной почте.
            <br />
            <br />
            <span>Мы гарантируем:</span> <br />
            - Безопасную транспортировку автомобилей;
            <br />
            - Договор с физическими и юридическими лицами;
            <br />
            - Страхование перевозимых машин.
            <br />
            После заключения договора, вам будет нужно:
            <br />
            - Доставить транспортное средство на стоянку по указанному адресу;
            <br />
            - Получить акт осмотра и передачи транспортного средства от нашей
            компании;
            <br />
            - Внести предоплату 50% за услугу перевозки автомобиля;
            <br />
            - Как только транспорт будет доставлен в ваш город, наши сотрудники
            свяжутся с вами для получения, осмотра и оплаты за авто.
            <br />
            <br />
            Наши автовозы доставляют машину практически в любой крупный город
            России и ближнего зарубежья. Забронировать перевозку авто в регион
            можно по бесплатной горячей линии{" "}
            <a href="tel:88005551163" className={styles.phone}>
              8 (800) 555-11-63
            </a>{" "}
            или оставьте заявку на сайте. Нам доверяют перевозку дистрибьюторы
            Range Rover, Mercedes-Benz и Jaguar, Subaru, Mazda в России.
          </p>
        </div>

        <div className={styles.linksdirections}>
          <p className={styles.header}>
            <span className={styles.left}>Откуда - Куда</span>
            <span className={styles.right}>Стоимость</span>
          </p>
          {links.map((direction) => {
            const { id, start, end, cost, url } = direction;
            return (
              <Link
                key={`directionlink${id}`}
                className={styles.link}
                to={`/directions/${url}`}
              >
                <span className={styles.left}>
                  Перевозка автомобиля {start} - {end}
                </span>
                <span className={styles.leftshort}>
                  {start}-{end}
                </span>
                <span className={styles.right}>от {cost} руб.</span>
              </Link>
            );
          })}
        </div>
        <MapRoute />
        {/* <Info /> */}
        <Feedback />
      </section>
      <Footer />
    </motion.main>
  );
}

export default Directions;
